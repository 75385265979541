import logo from './assets/img/neuromlogo.png';
import './App.css';
import BannerGif from './assets/img/giphy.gif';
import ParticlesBackground from './components/ParticlesBackground';
import CarouelImage1 from "./assets/img/carousel-1.jpg"
import CarouelImage2 from "./assets/img/carousel-2.jpg";
import AboutImage1 from "./assets/img/about-1.jpg";
import AboutImage2 from "./assets/img/about-2.jpg";
import ProjectImage1 from "./assets/img/project-1.jpg";
import ProjectImage2 from "./assets/img/project-2.jpg";
import ProjectImage3 from "./assets/img/project-3.jpg";
import ProjectImage4 from "./assets/img/project-4.jpg";
import ProjectImage5 from "./assets/img/project-5.jpg";
import ProjectImage6 from "./assets/img/project-6.jpg";
import BlogImage1 from "./assets/img/blog-1.jpg";
import BlogImage2 from "./assets/img/blog-2.jpg";
import BlogImage3 from "./assets/img/blog-3.jpg";
import TeamImage1 from "./assets/img/team-1.jpg";
import TeamImage2 from "./assets/img/team-2.jpg";
import TeamImage3 from "./assets/img/team-3.jpg";
import TeamImage4 from "./assets/img/team-4.jpg";
import TestimonialImag1 from "./assets/img/testimonial-1.jpg";
import TestimonialImag2 from "./assets/img/testimonial-2.jpg";
import TestimonialImag3 from "./assets/img/testimonial-3.jpg";
import TestimonialImag4 from "./assets/img/testimonial-4.jpg";
// import { particlesJS } from "https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js";
import React, { useEffect, useRef } from 'react';
import './assets/css/style.css'; // Import the CSS file
import './assets/lib/animate/animate.min.css';
import './assets/lib/owlcarousel/assets/owl.carousel.min.css';
import './assets/css/bootstrap.min.css';
// import { tsParticles } from "https://cdn.jsdelivr.net/npm/@tsparticles/engine@3.0.0/+esm";
// import { loadAll } from "https://cdn.jsdelivr.net/npm/@tsparticles/all@3.0.0/+esm";
function App() {
/* ---- particles.js config ---- */
const particlesContainerRef = useRef(null);

useEffect(() => {
  if (particlesContainerRef.current) {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js';
    script.onload = () => {
      window.particlesJS('particles-js', {
        particles: {
          number: {
            value: 300,
            density: {
              enable: true,
              value_area: 1000
            }
          },
          color: {
            value: ["#aa73ff", "#f8c210", "#83d238", "#33b1f8"]
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#fff"
            },
            polygon: {
              nb_sides: 5
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100
            }
          },
          opacity: {
            value: 0.6,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 2,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 120,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: {
              distance: 200,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true
      });
    };

    document.head.appendChild(script);
  }
}, []);


    return (
      <div>
      {/* Spinner Start */}
      {/* <div id="spinner" className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-grow text-primary" role="status" />
      </div> */}
      {/* Spinner End */}
      {/* Topbar Start */}
      {/* <div className="container-fluid bg-dark py-2 d-none d-md-flex">
        <div className="container">
          <div className="d-flex justify-content-between topbar">
            <div className="top-info">
              <small className="me-3 text-white-50"><a href="#"><i className="fas fa-map-marker-alt me-2 text-secondary" /></a>23 Ranking Street, Hyderabad</small>
              <small className="me-3 text-white-50"><a href="#"><i className="fas fa-envelope me-2 text-secondary" /></a>Email@Example.com</small>
            </div>
            <div id="note" className="text-secondary d-none d-xl-flex"><small>Note : We help you to Grow your Business</small></div>
            <div className="top-link">
              <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-facebook-f text-primary" /></a>
              <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-twitter text-primary" /></a>
              <a href className="bg-light nav-fill btn btn-sm-square rounded-circle"><i className="fab fa-instagram text-primary" /></a>
              <a href className="bg-light nav-fill btn btn-sm-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary" /></a>
            </div>
          </div>
        </div>
      </div> */}
      {/* Topbar End */}
      {/* Navbar Start */}
      <div className="container-fluid bg-white">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-0">
            <a href="#" className="navbar-brand">
              <h1 className="text-white fw-bold d-block"><img width={100} height={'auto'} src={logo} /></h1>
            </a>
            <button type="button" className="navbar-toggler me-0" style={{background:'#66023C'}} data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
              <div className="navbar-nav ms-auto mx-xl-auto p-0">
                <a href="#" className="nav-item nav-link active text-secondary">Home</a>
                <a href="#" className="nav-item nav-link">Rizz</a>
                <a href="#aboutus" className="nav-item nav-link">About us</a>
                {/* <a href="project.html" className="nav-item nav-link">Contacts</a> */}
                {/* <div className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                  <div className="dropdown-menu rounded">
                    <a href="blog.html" className="dropdown-item">Our Blog</a>
                    <a href="team.html" className="dropdown-item">Our Team</a>
                    <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                    <a href="404.html" className="dropdown-item">404 Page</a>
                  </div>
                </div> */}
                <a href="#contactus" className="nav-item nav-link">Contact</a>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shirink-0">
              {/* <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                <a href className="position-relative animated tada infinite">
                  <i className="fa fa-phone-alt text-white fa-2x" />
                  <div className="position-absolute" style={{top: '-7px', left: 20}}>
                    <span><i className="fa fa-comment-dots text-secondary" /></span>
                  </div>
                </a>
              </div> */}
              {/* <div className="d-flex flex-column pe-4 border-end">
                <span className="text-white-50">Have any questions?</span>
                <span className="text-secondary">Call: (+91)8121452131</span>
              </div> */}
              <div className="d-flex align-items-center justify-content-center ms-4 ">
                <a href="#"><i className="bi bi-search text-white fa-2x" /> </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {/* Navbar End */}
      {/* Carousel Start */}

      <div id="particles-js" ref={particlesContainerRef} style={{ background: '#000', height: '100vh' }}>
      <div className="neurom-main-content">
        <h3 className="text-secondary h1 animated fadeInUp" style={{textAlign:'center'}}>Neurom</h3>
        <p className="mb-4 text-white fs-7 animated fadeInDown" style={{fontSize:'24px',fontFamily:'"Inter",sans-serif'}}>We are an eccentric technology platform for the generation of spatial computing, AI, IoT & Digital Twins.</p>
      </div>
    </div>
      
      {/* <div className="container-fluid px-0">
        <div id="carouselId" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
            <div id="particles-js" ref={particlesContainerRef} style={{background:'#000'}}></div>
              <div className="carousel-caption">
                <div className="container carousel-content">
                  <h6 className="text-secondary h4 animated fadeInUp">Neurom Innovations</h6>
                  <p className="mb-4 text-white fs-5 animated fadeInDown">We are an eccentric technology platform for the generation of spatial compuitng, AI, IoT & Digital Twins.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Carousel End */}
      {/* Fact Start */}
      {/* <div className="container-fluid bg-secondary py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".1s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">99</h1>
                <h5 className="text-white mt-1">Success in getting happy customer</h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".3s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">25</h1>
                <h5 className="text-white mt-1">Thousands of successful business</h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".5s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">120</h1>
                <h5 className="text-white mt-1">Total clients who love Neurom</h5>
              </div>
            </div>
            <div className="col-lg-3 wow fadeIn" data-wow-delay=".7s">
              <div className="d-flex counter">
                <h1 className="me-3 text-primary counter-value">5</h1>
                <h5 className="text-white mt-1">Stars reviews given by satisfied clients</h5>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Fact End */}
      {/* About Start */}
      <div className="container-fluid py-5 my-5" id="aboutus">
        <div className="container pt-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".3s">
              <div className="h-100 position-relative">
                <img src={AboutImage1} className="img-fluid w-75 rounded" alt style={{marginBottom: '25%'}} />
                <div className="position-absolute w-75" style={{top: '25%', left: '25%'}}>
                  <img src={AboutImage2} className="img-fluid w-100 rounded" alt />
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12 wow fadeIn" data-wow-delay=".5s">
              <h5 className="text-primary">About Us</h5>
              {/* <h1 className="mb-4">About Neurom Agency And It's Innovative IT Solutions</h1> */}
              <p>Welcome to Neurom, where innovation meets the reality. We are a dynamic team of tech enthusiasts dedicated to pushing the boundaries of what's possible in the digital realm. Our mission is to revolutionize the way businesses leverage technology, offering cutting-edge solutions that drive growth, efficiency, and success. With a passion for innovation, our team combines expertise in various IT domains, ensuring we stay at the forefront of technological advancements. Whether it's AI, spatial computing or digital twins.</p>
              <p className="mb-4">At the heart of our approach lies the essence of storytelling, breathing life into every line of code and pixel of design. We believe in the transformative power of narrative to elevate your app beyond functionality – creating an immersive, engaging experience that resonates with users on a profound level. It's not just an application; it's a living, evolving story waiting to be explored</p>
              
              
              <a href className="btn btn-secondary rounded-pill px-5 py-3 text-white">More Details</a>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Services Start */}
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Our Services</h5>
            {/* <h1>Services Built Specifically For Your Business</h1> */}
          </div>
          <div className="row g-5 services-inner">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-code fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Digital Twins</h4>
                    <p className="mb-4">A world of limitless possibilities, where extended realities converge to rewrite reality and redefine how we interact with the world like never before.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-file-code fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Product Development</h4>
                    <p className="mb-4">Crafting digital experiences that resonate – our product development prowess goes beyond code; it's the art of turning ideas into captivating online realities.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-external-link-alt fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">AI & IoT</h4>
                    <p className="mb-4">Our expertise in AI and IoT converges to shape a smarter, more interconnected and inclusive world of co existance between beings and machines.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-user-secret fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Web Cecurity</h4>
                    <p className="mb-4">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-envelope-open fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Digital Marketing</h4>
                    <p className="mb-4">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-laptop fa-7x mb-4 text-primary" />
                    <h4 className="mb-3">Programming</h4>
                    <p className="mb-4">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt.</p>
                    <a href className="btn btn-secondary text-white px-5 py-3 rounded-pill">Read More</a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Services End */}
      {/* Project Start */}
      <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Case Studies</h5>
            {/* <h1>Our Recently Completed Projects</h1> */}
          </div>
          <div className="row g-5">
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img src={ProjectImage1} className="img-fluid w-100 rounded" alt />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">TATA Steel</h4>
                      <p className="m-0 text-white">Maintenance</p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img src={ProjectImage2} className="img-fluid w-100 rounded" alt />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Mahindra</h4>
                      <p className="m-0 text-white">AR Experince </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img src={ProjectImage3} className="img-fluid w-100 rounded" alt />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">Honda</h4>
                      <p className="m-0 text-white">Safety Modules</p>
                    </a>if
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img src={ProjectImage4} className="img-fluid w-100 rounded" alt />
                  <div className="project-content">
                    <a href="#" className="text-center">
                      <h4 className="text-secondary">product development</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Project End */}
      {/* Blog Start */}
      {/* <div className="container-fluid blog py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Our Blog</h5>
            <h1>Latest Blog &amp; News</h1>
          </div>
          <div className="row g-5 justify-content-center">
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".3s">
              <div className="blog-item position-relative bg-light rounded">
                <img src={BlogImage1} className="img-fluid w-100 rounded-top" alt />
                <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: 20}}>Web Design</span>
                <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <a href className="btn text-white">Read More</a>
                  </div>
                  <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div className="blog-icon-1">
                      <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3" /></p>
                    </div>
                    <div className="blog-icon-2">
                      <a href className="btn me-1"><i className="fab fa-facebook-f text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-twitter text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-instagram text-white" /></a>
                    </div>
                  </div>
                </div>
                <div className="blog-content text-center position-relative px-3" style={{marginTop: '-25px'}}>
                  <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt />
                  <h5 className>By Daniel Martin</h5>
                  <span className="text-secondary">24 March 2023</span>
                  <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                </div>
                <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <a href className="text-white"><small><i className="fas fa-share me-2 text-secondary" />5324 Share</small></a>
                  <a href className="text-white"><small><i className="fa fa-comments me-2 text-secondary" />5 Comments</small></a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".5s">
              <div className="blog-item position-relative bg-light rounded">
                <img src={BlogImage2} className="img-fluid w-100 rounded-top" alt />
                <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: 20}}>Development</span>
                <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <a href className="btn text-white ">Read More</a>
                  </div>
                  <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div className="blog-icon-1">
                      <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3" /></p>
                    </div>
                    <div className="blog-icon-2">
                      <a href className="btn me-1"><i className="fab fa-facebook-f text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-twitter text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-instagram text-white" /></a>
                    </div>
                  </div>
                </div>
                <div className="blog-content text-center position-relative px-3" style={{marginTop: '-25px'}}>
                  <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt />
                  <h5 className>By Daniel Martin</h5>
                  <span className="text-secondary">23 April 2023</span>
                  <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                </div>
                <div className="blog-coment d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <a href className="text-white"><small><i className="fas fa-share me-2 text-secondary" />5324 Share</small></a>
                  <a href className="text-white"><small><i className="fa fa-comments me-2 text-secondary" />5 Comments</small></a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4 wow fadeIn" data-wow-delay=".7s">
              <div className="blog-item position-relative bg-light rounded">
                <img src={BlogImage3} className="img-fluid w-100 rounded-top" alt />
                <span className="position-absolute px-4 py-3 bg-primary text-white rounded" style={{top: '-28px', right: 20}}>Mobile App</span>
                <div className="blog-btn d-flex justify-content-between position-relative px-3" style={{marginTop: '-75px'}}>
                  <div className="blog-icon btn btn-secondary px-3 rounded-pill my-auto">
                    <a href className="btn text-white ">Read More</a>
                  </div>
                  <div className="blog-btn-icon btn btn-secondary px-4 py-3 rounded-pill ">
                    <div className="blog-icon-1">
                      <p className="text-white px-2">Share<i className="fa fa-arrow-right ms-3" /></p>
                    </div>
                    <div className="blog-icon-2">
                      <a href className="btn me-1"><i className="fab fa-facebook-f text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-twitter text-white" /></a>
                      <a href className="btn me-1"><i className="fab fa-instagram text-white" /></a>
                    </div>
                  </div>
                </div>
                <div className="blog-content text-center position-relative px-3" style={{marginTop: '-25px'}}>
                  <img src="img/admin.jpg" className="img-fluid rounded-circle border border-4 border-white mb-3" alt />
                  <h5 className>By Daniel Martin</h5>
                  <span className="text-secondary">30 jan 2023</span>
                  <p className="py-2">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum. Aliquam dolor eget urna ultricies tincidunt libero sit amet</p>
                </div>
                <div className="blog-coments d-flex justify-content-between px-4 py-2 border bg-primary rounded-bottom">
                  <a href className="text-white"><small><i className="fas fa-share me-2 text-secondary" />5324 Share</small></a>
                  <a href className="text-white"><small><i className="fa fa-comments me-2 text-secondary" />5 Comments</small></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Blog End */}
      {/* Team Start */}
      {/* <div className="container-fluid py-5 mb-5 team">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Our Team</h5>
            <h1>Meet our expert Team</h1>
          </div>
          <div className="owl-carousel team-carousel wow fadeIn" data-wow-delay=".5s">
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img src={TeamImage1} className="img-fluid w-100 rounded-circle" alt />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 className>Full Name</h4>
                    <p className="m-0">Designation</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-facebook-f" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-twitter" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-instagram" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-linkedin-in" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img src={TeamImage2} className="img-fluid w-100 rounded-circle" alt />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 className>Full Name</h4>
                    <p className="m-0">Designation</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-facebook-f" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-twitter" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-instagram" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-linkedin-in" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img src={TeamImage3} className="img-fluid w-100 rounded-circle" alt />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 className>Full Name</h4>
                    <p className="m-0">Designation</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-facebook-f" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-twitter" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-instagram" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-linkedin-in" /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded team-item">
              <div className="team-content">
                <div className="team-img-icon">
                  <div className="team-img rounded-circle">
                    <img src={TeamImage4} className="img-fluid w-100 rounded-circle" alt />
                  </div>
                  <div className="team-name text-center py-3">
                    <h4 className>Full Name</h4>
                    <p className="m-0">Designation</p>
                  </div>
                  <div className="team-icon d-flex justify-content-center pb-4">
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-facebook-f" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-twitter" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-instagram" /></a>
                    <a className="btn btn-square btn-secondary text-white rounded-circle m-1" href><i className="fab fa-linkedin-in" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Team End */}
      {/* Testimonial Start */}
      {/* <div className="container-fluid testimonial py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Our Testimonial</h5>
            <h1>Our Client Saying!</h1>
          </div>
          <div className="owl-carousel testimonial-carousel wow fadeIn" data-wow-delay=".5s">
            <div className="testimonial-item border p-4">
              <div className="d-flex align-items-center">
                <div className>
                  <img src={TestimonialImag1} alt />
                </div>
                <div className="ms-4">
                  <h4 className="text-secondary">Client Name</h4>
                  <p className="m-0 pb-3">Profession</p>
                  <div className="d-flex pe-5">
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                  </div>
                </div>
              </div>
              <div className="border-top mt-4 pt-3">
                <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
              </div>
            </div>
            <div className="testimonial-item border p-4">
              <div className=" d-flex align-items-center">
                <div className>
                  <img src={TestimonialImag2} alt />
                </div>
                <div className="ms-4">
                  <h4 className="text-secondary">Client Name</h4>
                  <p className="m-0 pb-3">Profession</p>
                  <div className="d-flex pe-5">
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                  </div>
                </div>
              </div>
              <div className="border-top mt-4 pt-3">
                <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
              </div>
            </div>
            <div className="testimonial-item border p-4">
              <div className=" d-flex align-items-center">
                <div className>
                  <img src={TestimonialImag3} alt />
                </div>
                <div className="ms-4">
                  <h4 className="text-secondary">Client Name</h4>
                  <p className="m-0 pb-3">Profession</p>
                  <div className="d-flex pe-5">
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                  </div>
                </div>
              </div>
              <div className="border-top mt-4 pt-3">
                <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
              </div>
            </div>
            <div className="testimonial-item border p-4">
              <div className=" d-flex align-items-center">
                <div className>
                  <img src={TestimonialImag4} alt />
                </div>
                <div className="ms-4">
                  <h4 className="text-secondary">Client Name</h4>
                  <p className="m-0 pb-3">Profession</p>
                  <div className="d-flex pe-5">
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                    <i className="fas fa-star me-1 text-primary" />
                  </div>
                </div>
              </div>
              <div className="border-top mt-4 pt-3">
                <p className="mb-0">Lorem ipsum dolor sit amet elit. Sed efficitur quis purus ut interdum aliquam dolor eget urna. Nam volutpat libero sit amet leo cursus, ac viverra eros morbi quis quam mi.</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Testimonial End */}
      {/* Contact Start */}
      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth: 600}}>
            <h5 className="text-primary">Get In Touch</h5>
            <h1 className="mb-3">Contact for any query</h1>
            {/* <p className="mb-2">The contact form is currently inactive. Get a functional and working contact form with Ajax &amp; PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p> */}
          </div>
          <div className="contact-detail position-relative p-5">
            <div className="row g-5 mb-5 justify-content-center">
              <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div className="d-flex bg-light p-3 rounded">
                  <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: 64, height: 64}}>
                    <i className="fas fa-map-marker-alt text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Address</h4>
                    <a href="#" target="_blank" className="h5">Hyderabad</a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
                <div className="d-flex bg-light p-3 rounded">
                  <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: 64, height: 64}}>
                    <i className="fa fa-phone text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Call Us</h4>
                    <a className="h5" href="tel:(+91)8121452131" target="_blank">(+91)8121452131</a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
                <div className="d-flex bg-light p-3 rounded">
                  <div className="flex-shrink-0 btn-square bg-secondary rounded-circle" style={{width: 64, height: 64}}>
                    <i className="fa fa-envelope text-white" />
                  </div>
                  <div className="ms-3">
                    <h4 className="text-primary">Email Us</h4>
                    <a className="h5" href="mailto:sumedh@seiky.in" target="_blank">sumedh@seiky.in</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".3s">
                <div className="p-5 h-100 rounded contact-map">
                <iframe
      title="Google Maps"
      className="rounded w-100 h-100"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.023877056109!2d78.2928301!3d17.4554454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb93da8c1e7057%3A0x704b969717cc250d!2sMadhapur%2C%20LVS%20Arcade%2C%2071%2C%20Jubilee%20Enclave%2C%20HITEC%20City%2C%20Hyderabad%2C%20Telangana%20500081!5e0!3m2!1sen!2sin!4v1618516770612!5m2!1sen!2sin"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
                </div>
              </div>
              <div className="col-lg-6 wow fadeIn" data-wow-delay=".5s" id="contactus">
                <div className="p-5 rounded contact-form">
                  <div className="mb-4">
                    <input type="text" className="form-control border-0 py-3" placeholder="Your Name" />
                  </div>
                  <div className="mb-4">
                    <input type="email" className="form-control border-0 py-3" placeholder="Your Email" />
                  </div>
                  <div className="mb-4">
                    <input type="text" className="form-control border-0 py-3" placeholder="Project" />
                  </div>
                  <div className="mb-4">
                    <textarea className="w-100 form-control border-0 py-3" rows={6} cols={10} placeholder="Message" defaultValue={""} />
                  </div>
                  <div className="text-start">
                    <button className="btn bg-primary text-white py-3 px-5" type="button">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
      {/* Contact End */}
      {/* Footer Start */}
      <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
        <div className="container pt-5 pb-4">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <a href="#">
                <h1 className="text-white fw-bold d-block"><img width={100} height={'auto'} src={logo} /> </h1>
              </a>
              <p className="mt-4 text-light">At Neurom, we thrive on creativity and problem-solving. Our mission is to revolutionize the way businesses leverage technology, offering cutting-edge solutions that drive growth, efficiency, and success.</p>
              <div className="d-flex Neurom-link">
                <a href className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-facebook-f text-primary" /></a>
                <a href className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-twitter text-primary" /></a>
                <a href className="btn-light nav-fill btn btn-square rounded-circle me-2"><i className="fab fa-instagram text-primary" /></a>
                <a href className="btn-light nav-fill btn btn-square rounded-circle me-0"><i className="fab fa-linkedin-in text-primary" /></a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3 text-secondary">Short Link</a>
              <div className="mt-4 d-flex flex-column short-link">
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />About us</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Contact us</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Our Services</a>
                {/* <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Our Projects</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Latest Blog</a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3 text-secondary">Help Link</a>
              <div className="mt-4 d-flex flex-column help-link">
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Terms Of use</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Privacy Policy</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Helps</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />FQAs</a>
                <a href className="mb-2 text-white"><i className="fas fa-angle-right text-secondary me-2" />Contact</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <a href="#" className="h3 text-secondary">Contact Us</a>
              <div className="text-white mt-4 d-flex flex-column contact-link">
                <a href="#" className="pb-3 text-light border-bottom border-primary"><i className="fas fa-map-marker-alt text-secondary me-2" />91springboard Hitec City Madhapur, LVS Arcade, 71, Jubilee Enclave, HITEC City, Hyderabad, Telangana 500081</a>
                <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-phone-alt text-secondary me-2" /> (+91)8121452131</a>
                <a href="#" className="py-3 text-light border-bottom border-primary"><i className="fas fa-envelope text-secondary me-2" /> sumedh@seiky.in</a>
              </div>
            </div>
          </div>
          <hr className="text-light mt-5 mb-4" />
          <div className="row">
            <div className="col-md-6 text-center text-md-start">
              <span className="text-light"><a href="#" className="text-secondary"><i className="fas fa-copyright text-secondary me-2" />Neurom Innovation Private Limited</a>, All right reserved.</span>
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-secondary btn-square rounded-circle back-to-top"><i className="fa fa-arrow-up text-white" /></a>
      </div>
    

  );
}

export default App;
